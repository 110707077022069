'use client';

/* eslint-disable react-hooks/exhaustive-deps */
import { Box, IconButton, iconButtonClasses, styled } from '@mui/material';
import {
  MaterialDesignContent,
  SnackbarProvider as NotiStackProvider,
} from 'notistack';
import React, { useCallback, useRef } from 'react';
import CloseIcon from 'src/assets/icons/close.svg';
import SuccessTickIcon from 'src/assets/icons/success-tick.svg?url';
import { makeStyles } from 'tss-react/mui';

const Success = styled(MaterialDesignContent)(({ theme }) => {
  return {
    '&.notistack-MuiContent-success': {
      backgroundColor: theme.palette.success.main,
    },
  };
});

const Error = styled(MaterialDesignContent)(({ theme }) => {
  return {
    '&.notistack-MuiContent-error': {
      backgroundColor: theme.palette.error.main,
    },
  };
});

const useStyles = makeStyles()((theme) => ({
  snackbar: {
    minWidth: 300,
    '& .notistack-MuiContent': {
      padding: theme.spacing(1.5),
      display: 'flex',

      '& path': {
        fill: theme.palette.custom.content[1],
      },
    },

    '& .ubet-err': {
      width: 600,
      minHeight: 60,
      [`& .${iconButtonClasses.sizeSmall}`]: {
        position: 'absolute',
        right: 12,
        top: 24,
      },
    },

    '& #notistack-snackbar': {
      padding: 0,
      fontSize: 14,
      lineHeight: '18px',
      maxWidth: 'calc(100% - 48px)',
    },
  },
}));

export const SnackbarProvider: React.FCC = ({ children }) => {
  const { classes, cx } = useStyles();
  const snackbarRef = useRef<any>();

  const action = useCallback(
    (key: any) => (
      <IconButton
        sx={{ my: -1 }}
        aria-label="close"
        color="inherit"
        onClick={() => snackbarRef.current.closeSnackbar(key)}>
        <CloseIcon />
      </IconButton>
    ),
    [],
  );

  return (
    <NotiStackProvider
      iconVariant={{
        success: (
          <Box
            sx={{
              backgroundImage: `url(${SuccessTickIcon.src})`,
              height: 16,
              minWidth: 16,
              mr: '12px',
            }}
          />
        ),
      }}
      ref={snackbarRef}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
      classes={{ root: classes.snackbar }}
      Components={{ success: Success, error: Error }}
      autoHideDuration={5000}
      preventDuplicate
      maxSnack={3}
      action={action}>
      {children}
    </NotiStackProvider>
  );
};
