'use client';

/* eslint-disable lingui/no-unlocalized-strings */
/* eslint-disable react-hooks/exhaustive-deps */
import { Trans } from '@lingui/macro';
import { Box, Typography } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomEffect } from 'jotai-effect';
import { useSearchParams } from 'next/navigation';
import { useSnackbar } from 'notistack';
import React, { Suspense, useEffect } from 'react';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { UnsupportedIPPage } from 'src/_pages/unsupported-page';
import { connectSocketAtom, socketAtom } from 'src/hooks/socket/use-socket';
import { useSocketEventFn } from 'src/hooks/socket/use-socket-fn';
import { useMyPathname, useMyRouter } from 'src/hooks/use-locale';
import { useQuery } from 'src/hooks/use-query';
import {
  _fingerprintAtom,
  accessTokenAtom,
  blockAtom,
  cleanUpLoggedWaiterAtom,
  kycAtom,
  localeAtom,
  loggedAtom,
  logoutAtom,
  profileAtom,
  userIdAtom,
} from 'src/store/auth';
import { categoriesAtom } from 'src/store/casino';
import { userGeolocationAtom, userPrefAtom } from 'src/store/layout';
import {
  betSourcesAtom,
  blacklistCountriesAtom,
  configsAtom,
  matchStatusesAtom,
} from 'src/store/settings';
import { liveCountAtom, sportAtom, tournamentAtom } from 'src/store/sport';
import { balancesAtom, coinsAtom, pricesAtom } from 'src/store/wallet';
import { Paths } from 'src/utils/constants/path';
import { SocketEvent } from 'src/utils/constants/socket-event';
import { isIOS } from 'src/utils/helpers/etc';
import {
  clientCookie,
  clientFp,
  clientJWT,
  clientUserPref,
} from 'src/utils/helpers/headers/headers.client';
import { isGEOBlocked, isUnauthorizedError } from 'src/utils/helpers/my-error';
import { trackPageView } from 'src/utils/helpers/rudderstack';
import { request } from 'src/utils/request';
import { KYCStatusKey } from 'src/utils/types/auth';

const pollingAtom = atomEffect((get, set) => {
  const logged = get(loggedAtom);

  let token: any;
  if (logged) {
    token = setInterval(() => {
      set(pricesAtom);
      set(betSourcesAtom);
    }, 30000);
  }
  return () => {
    clearInterval(token);
  };
});

const registerSocketAtom = atomEffect((get, set) => {
  const socket = get(socketAtom);
  if (!socket) return;

  const token = get(accessTokenAtom);
  const userId = get(userIdAtom);

  if (!token || !userId) return;

  socket.emit(SocketEvent.Identity, { token, userId });
  return () => {
    socket.emit(SocketEvent.Identity, {});
  };
});

const onFirstTimeAtom = atomEffect((__, set) => {
  const done = () => {
    // set(appStateAtom, (prev) => Math.max(prev, AppState.READY));
  };

  const token = setTimeout(done, 1500);

  const jwt = clientJWT();

  const interceptor = request.interceptors.response.use(
    undefined,
    (err: any) => {
      if (isUnauthorizedError(err)) {
        set(logoutAtom);
      }

      if (isGEOBlocked(err)) {
        set(blockAtom, true);
      }

      return Promise.reject(err);
    },
  );

  Promise.allSettled([
    set(sportAtom),
    ...(jwt
      ? [set(accessTokenAtom, jwt), set(profileAtom)]
      : [set(logoutAtom)]),
    set(_fingerprintAtom, clientFp()),
    set(tournamentAtom),
    set(categoriesAtom),
    set(localeAtom),
    set(userPrefAtom, clientUserPref()),
    set(coinsAtom),
    set(pricesAtom),
    set(configsAtom),
    set(matchStatusesAtom),
    set(blacklistCountriesAtom),
  ]).finally(done);

  return () => {
    clearTimeout(token);
    request.interceptors.response.eject(interceptor);
  };
});

const InnerRoot: React.FCC = ({ children }) => {
  useAtom(onFirstTimeAtom);
  useAtom(pollingAtom);
  useAtom(registerSocketAtom);
  useAtom(connectSocketAtom);
  useAtom(cleanUpLoggedWaiterAtom);
  const { enqueueSnackbar } = useSnackbar();
  const userId = useAtomValue(userIdAtom);
  const blocked = useAtomValue(blockAtom);
  // const loading = useAtomValue(appStateAtom) !== AppState.DONE;
  const logout = useSetAtom(logoutAtom);
  const fetchBalances = useSetAtom(balancesAtom);
  const updateLiveCount = useSetAtom(liveCountAtom);

  const setKyc = useSetAtom(kycAtom);

  // when user execute a transaction, we need to update
  useSocketEventFn(
    [SocketEvent.UserTransaction, SocketEvent.UserBalance, SocketEvent.MyBets],
    fetchBalances,
  );

  useSocketEventFn(
    SocketEvent.KYC,
    (data: { kyc_status: KYCStatusKey } | KYCStatusKey) => {
      const key = typeof data === 'string' ? data : data?.kyc_status;

      if (key === KYCStatusKey.KYC_DONE) {
        enqueueSnackbar(
          <Box>
            <Typography fontWeight={700}>
              <Trans id="verify_successfully_toast">
                Verification successful
              </Trans>
            </Typography>
            <Typography>
              <Trans id="verify_successfully_toast_description">
                Withdrawals have been activated.
              </Trans>
            </Typography>
          </Box>,
          { variant: 'success', key },
        );
      }

      setKyc(key);
    },
  );

  useSocketEventFn(SocketEvent.CountLiveNow, updateLiveCount);
  useSocketEventFn(SocketEvent.Logout, (_userId: string | number) => {
    if (+_userId === userId) logout();
  });

  useEffectOnce(() => {
    if (isIOS()) {
      window.document.addEventListener(
        'touchmove',
        (e) => {
          // disable if 2 fingers and event gonna change scale
          // @ts-ignore
          if (e.scale !== 1 || e.touches.length >= 2) {
            e.preventDefault();
          }
        },
        { passive: false },
      );
    }
  });

  const { from } = useQuery('from');
  if (from === 'casino_return') {
    return null;
  }

  if (blocked) {
    return <UnsupportedIPPage />;
  }

  return children;

  // // support ssr
  // if (isServer()) {
  //   return (
  //     <React.Fragment key="ssr">
  //       <Static />
  //       {children}
  //     </React.Fragment>
  //   );
  // }

  // return (
  //   <React.Fragment key="csr">
  //     <Backdrop open={loading} sx={{ bgcolor: 'background.default' }}>
  //       <Animated />
  //     </Backdrop>
  //     {!loading && children}
  //   </React.Fragment>
  // );
};

const Tracker: React.FC = () => {
  const { pathname } = useMyPathname();

  useEffect(() => {
    trackPageView();
  }, [pathname]);

  return null;
};

const BTag: React.FC = () => {
  const searchParams = useSearchParams();
  const setUserLocation = useSetAtom(userGeolocationAtom);

  const btag = searchParams.get('btag');

  useEffect(() => {
    if (!btag) return;

    // if there is a btag in the url , store it in the cookies
    // Check if a cookie already exists
    const prevBTag = clientCookie.get('btag');
    // if a btag is stored, ignore the new one
    if (prevBTag) {
      return;
    }
    // Store the btag in the cookies for 30 days
    clientCookie.set('btag', btag, { maxAge: 30 * 24 * 60 * 60 });
  }, [btag]);

  const ipCountry = searchParams.get('country');
  useEffect(() => {
    setUserLocation((prev) => {
      return { ...prev, ipCountry: prev.ipCountry ?? ipCountry };
    });
  }, [ipCountry]);

  return null;
};

const AutoLogout: React.FC = () => {
  const logged = useAtomValue(loggedAtom);
  const { pathname } = useMyPathname();
  const router = useMyRouter();

  useUpdateEffect(() => {
    if (!logged && pathname.startsWith(Paths.Dashboard)) {
      router.replace(Paths.Home);
    }
  }, [logged]);

  return null;
};

export const RootTemplate: React.FCC = ({ children }) => {
  return (
    <Suspense>
      <InnerRoot>
        <Tracker />
        <AutoLogout />
        <BTag />
        {children}
      </InnerRoot>
    </Suspense>
  );
};
